import React, { useState, useEffect } from "react";
import { sdmUrl } from "../../Api";
import { useNavigate, useParams, Link } from 'react-router-dom';
import Loader from '../../loader/Loader';

const LeadView = () => {

    let base64     = require('base-64');
    const navigate = useNavigate();

    const [gallery_id, setGalleryId]         = useState(base64.decode(useParams().id));
    const [loading, setLoading]              = useState(false);
    const [leadViewData, setLeadViewData]    = useState([""]);

    const usr_token = localStorage.getItem("authToken");

    const getLeadviewData = async (e) => {
       
        console.log(JSON.stringify({usr_token, gallery_id}));
        setLoading(true);
        try {
            fetch(sdmUrl.concat("/gallery/lead_view"), {
                method: "POST",
                body: JSON.stringify({usr_token, gallery_id}),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    console.log(result);
                    setLeadViewData(result);
    
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getLeadviewData();
    }, []);

    const handleImagedata = (folder, id) => {
        console.log(folder);
        sessionStorage.removeItem("solutionfolder");
        sessionStorage.setItem("solutionfolder", folder);
        navigate(`/image_gallery/${base64.encode(id)}`);
    };

  return (
    <>
      {loading && (
            <>
                <Loader/>
            </>
      ) }
     <div className="row">
        <div className="col-md-12">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                        <div className="d-none d-md-flex"><h4>Lead View</h4></div>
                        <div className="d-md-flex gap-4 align-items-center">
                           
                        </div>
                        <div className="dropdown ms-auto">
                            <Link to={`/gallery_leads`} className="btn btn-primary" > <i className="fa fa-arrow-circle-left m-1"></i> Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card" style={{overflowX:'auto'}}>
                <div className="card-body">
                    <div className="invoice row">
                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                            <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                <thead className="thead-light">
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>LOB</th>
                                        <td >{leadViewData != '' && leadViewData.lob_type}</td>
                                    </tr>
                                    <tr>
                                        <th>Services</th>
                                        <td>{leadViewData != '' && leadViewData.inventory_type}</td>
                                    </tr>
                                    <tr>
                                        <th>Oem Name</th>
                                        <td>{leadViewData != '' && leadViewData.oem_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Component Name</th>
                                        <td >{leadViewData != '' && leadViewData.component_name}</td>
                                    </tr>
                                    <tr>
                                        <th >Component Family</th>
                                        <td>{leadViewData != '' && leadViewData.component_family}</td>
                                    </tr>  
                                    <tr>
                                        <th>Ul Category</th>
                                        <td>{leadViewData != '' && leadViewData.ul_category}</td>
                                    </tr>     
                                    <tr>
                                        <th>Project Name</th>
                                        <td >{leadViewData != '' && leadViewData.project_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Project Id</th>
                                        <td>{leadViewData != '' && leadViewData.project_id}</td>
                                    </tr>         
                                </tbody>
                            </table>
                        </div> 
                        <div className="table-scrollable col-md-6">
                            <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                <thead className="thead-light">
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Project Description</th>
                                        <td>{leadViewData != '' && leadViewData.project_desc}</td>
                                    </tr>
                                    <tr>
                                        <th >BD Name</th>
                                        <td>{leadViewData != '' && leadViewData.bd_person_name}</td>
                                    </tr> 
                                    <tr>
                                        <th>SD Name</th>
                                        <td >{leadViewData != '' && leadViewData.sd_person_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Solution View</th>
                                        <td><Link  to={`/image_gallery/${base64.encode(leadViewData.id)}`} onClick={() => handleImagedata(leadViewData.solution_folder, leadViewData.id)} ><span className='badge bg-primary' >Sol. View</span></Link></td>
                                    </tr>   
                                    <tr>
                                        <th>Trial View</th>
                                        <td><Link  to={`/image_gallery/${base64.encode(leadViewData.id)}`} onClick={() => handleImagedata(leadViewData.trial_folder, leadViewData.id)} ><span className='badge bg-primary' >Trial View</span></Link></td>
                                    </tr>     
                                    <tr>
                                        <th>Reference View</th>
                                        <td><Link  to={`/image_gallery/${base64.encode(leadViewData.id)}`} onClick={() => handleImagedata(leadViewData.reference_folder, leadViewData.id)} ><span className='badge bg-primary' >Ref. View</span></Link></td>
                                    </tr>
                                    <tr>
                                        <th>Tech Proposal View</th>
                                        <td><Link  to={`/image_gallery/${base64.encode(leadViewData.id)}`} onClick={() => handleImagedata(leadViewData.tech_proposal_folder, leadViewData.id)} ><span className='badge bg-primary' >Tech. View</span></Link></td>
                                    </tr>          
                                </tbody>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>


           
        </div>
    </div>
    </>
  )
}

export default LeadView