import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import DateFormat from '../dateformate/DateFormat';
import { sdmUrl } from "../../Api";
import { Routes, Route, NavLink, useNavigate, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
    LeadCount,
    LOBList,
    ProductList,
    ServicesList,
    BDList, 
    SDList, 
} from "../../apidata/fetchapi";
import Loader from '../../loader/Loader';

const GalleryLeads = () => {

    const { lobListData }      = LOBList();
    const { productListData }  = ProductList();
    const { servicesListData } = ServicesList();
    const { bdListData }       = BDList();
    const { sdListData }       = SDList();

    let base64     = require('base-64');
    const navigate = useNavigate();

    const usr_token = localStorage.getItem("authToken");
    let permissionmessage = sessionStorage.getItem("permissionmessage");
    let lob = sessionStorage.getItem("lobdata");
    let inventory = sessionStorage.getItem("servicedata");

    let servicename = sessionStorage.getItem("servicenamemydash");
    let servicenamemydash = JSON.parse(servicename);

    let bdname = sessionStorage.getItem("bdnamemydash");
    let bdnamemydash = JSON.parse(bdname);

    let sdname = sessionStorage.getItem("sdnamemydash");
    let sdnamemydash = JSON.parse(sdname);

    const initialData = {
        service_name: servicenamemydash,
        bd_name: bdnamemydash,
        sd_name: sdnamemydash,
        usr_token: usr_token,
    };

    const statusarr = [
        { value: "2", label: "Lead Pending at BD Head" },
        { value: "3", label: "Lead Assigned" },
        { value: "4", label: "Opportunity Pending at SD Head" },
        { value: "5", label: "WIP" },
        { value: "22", label: "Rejected" },
    ];
    

    const [loading, setLoading]                     = useState(false);
    const [leadsData, setLeadsData]                 = useState([""]);
    const [filteredLeads ,setFilteredLeads]         = React.useState([]);
    const [search ,setSearch]                       = React.useState("");
    const [dropdownData, setDropdownData]           = useState(initialData);

    
      const handleServicesInput = (data) => {
          sessionStorage.removeItem("servicenamemydash");
          sessionStorage.setItem("servicenamemydash", JSON.stringify(data));
    
          setDropdownData((oldValue) => ({
              ...oldValue,
              service_name: data,
          }));
      };
    
      const handleBDNameInput = (data) => {
        sessionStorage.removeItem("bdnamemydash");
        sessionStorage.setItem("bdnamemydash", JSON.stringify(data));
    
        setDropdownData((oldValue) => ({
            ...oldValue,
            bd_name: data,
        }));
        
      }
    
      const handleSDNameInput = (data) => {
          sessionStorage.removeItem("sdnamemydash");
          sessionStorage.setItem("sdnamemydash", JSON.stringify(data));
    
          setDropdownData((oldValue) => ({
              ...oldValue,
              sd_name: data,
          }));
      };
    
      const clearFilter = () =>{
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.removeItem("bdnamemydash");
        sessionStorage.removeItem("sdnamemydash");
    
        setDropdownData({
            lob_name: "",
            product_name: "",
            service_name: "",
            bd_name: "",
            sd_name: "",
            usr_token: usr_token,
        })
      }
   

    const getLeadsData = async (e) => {
       
        console.log(JSON.stringify({usr_token, lob, inventory}));
        setLoading(true);
        try {
            fetch(sdmUrl.concat("/gallery/lead_list"), {
                method: "POST",
                body: JSON.stringify({usr_token, lob, inventory}),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    console.log(result);
                    setLeadsData(result);
                    setFilteredLeads(result);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getLeadsData();
    }, []);

    const handleImagedata = (folder, id) => {
        console.log(folder);
        sessionStorage.removeItem("solutionfolder");
        sessionStorage.setItem("solutionfolder", folder);
        navigate(`/image_gallery/${base64.encode(id)}`);
    };

    useEffect(() => {
        const filterResult = leadsData.filter(data => {
            return (data.lob_type?.toLowerCase().match(search?.toLowerCase()) || data.inventory_type?.toLowerCase().match(search?.toLowerCase()) || data.oem_name?.toLowerCase().match(search?.toLowerCase()) || data.component_name?.toLowerCase().match(search?.toLowerCase()) || data.component_family?.toLowerCase().match(search?.toLowerCase()) || data.project_name?.toLowerCase().match(search?.toLowerCase()) || data.project_id?.toLowerCase().match(search?.toLowerCase()) || data.project_desc?.toLowerCase().match(search?.toLowerCase()) || data.ul_category?.toLowerCase().match(search?.toLowerCase()) );  
        });
    
        setFilteredLeads(filterResult);
       }, [search]); 

    const columns = [
        { 
          name: "S.No.",
          selector : (row) => row.id ,
          width : '80px',
          sortable :  true 
        },
        {
          name: "LOB",
          sortable :  true,  
          width : '80px',
          selector : (row) => row.lob_type,
        },
        {
          name: "Product Type",
          sortable :  true,  
          width : '130px',
          selector : (row) => row.inventory_type,
        },
        {
          name: "Oem Name",
          sortable :  true,  
          width : '110px',
          selector : (row) => row.oem_name,
          wrap: true
        },
        {
          name: "Component Name",
          sortable :  true,  
          width : '150px',
          selector : (row) => row.component_name,
          wrap: true
        },
        {
          name: "Component Family",
          sortable :  true,   
          width : '160px',
          selector : (row) => row.component_family,     
        },
        {
            name: "Project Name",
            sortable :  true,
            width : '140px',
            selector : (row) => row.project_name,
            wrap: true
        },
        {
          name: "Project Id",
          sortable :  true,
          width : '140px',
          selector : (row) => row.project_id
        },
        {
          name: "Ul Category",
          sortable :  true,
          width : '130px',
          selector : (row) => row.ul_category
        },
        {
          name: "BD Name",
          sortable :  true,
          width : '100px',
          selector : (row) => row.bd_person_name
        },
        {
          name: "Created At",
          sortable :  true,
          width : '110px',
          selector : (row) => DateFormat(row.created_at)
        },
        {
            name: "View",
            width : '150px',
            cell : (row) => 
            {
             return (
                <>
                <Link  to={`/image_gallery/${base64.encode(row.id)}`} onClick={() => handleImagedata(row.solution_folder, row.id)} ><span className='badge bg-primary' >Sol. View</span></Link>
                <Link  to={`/lead_view/${base64.encode(row.id)}`} style={{marginLeft:"3px"}} ><span className='badge bg-teal' >Lead view</span></Link>
                </>
              );
            }
        }, 
    ];

    useEffect(() => {
        if (permissionmessage != "") {
            setTimeout(() => {
                sessionStorage.removeItem("permissionmessage");
            }, 8000);
        }
    }, []);

    return (
        <>
            {permissionmessage && (
                <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                >
                    <strong>{permissionmessage}</strong>
                </div>
            )}
            {loading && (
                <>
                    <Loader/>
                </>
            ) }
           <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    
                    <div className="card">
                        <div className="card-header">
                            <h3 style={{float:'left'}}> Gallery Leads</h3> 
                            <button style={{font_size: '12px', float: 'right'}} type="button" onClick={clearFilter} className="btn btn-danger btn-sm  me-2"><strong>Clear Filter</strong></button>   
                            {/* <button style={{font_size: '12px', float: 'right'}} type="button" onClick={addLead} className="btn btn-primary btn-sm  me-2"><strong>Create Lead</strong></button>     */}
                            <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                            data={filteredLeads}
                            filename={"leads.csv"}
                            ><strong>Export</strong></CSVLink>
                        </div>
                        <div style={{marginLeft:'10px', marginRight:'10px'}}>
                          <form className="row"  encType="multipart/form-data">  
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Date :{" "}
                                  </label>
                                  <Form.Control
                                    type="date"
                                    name="date"
                                    // value={UsersCreate.date}
                                    // onChange={handleFormInput}
                                    id="date"
                                    className="form-control"  />
                              </div>                        
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Services :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={servicesListData}
                                      defaultValue={servicenamemydash}
                                      key={servicenamemydash}
                                      onChange={handleServicesInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      BD Name :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={bdListData}
                                      defaultValue={bdnamemydash}
                                      key={bdnamemydash}
                                      onChange={handleBDNameInput}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Sol. Design Exe. :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={sdListData}
                                      defaultValue={sdnamemydash}
                                      key={sdnamemydash}
                                      onChange={handleSDNameInput}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Status :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="status"
                                      options={statusarr}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                          </form>
                        </div>
                        <div className="card-body">         
                               <DataTable 
                               id="table-to-xls"
                               data={filteredLeads} 
                               columns={columns} 
                               pagination
                               //fixedHeader
                               //fixedHeaderScrollHeight='500px'
                               highlightOnHover
                               subHeader
                               subHeaderComponent = {
                                <div className="col-md-3" style={{float:'right'}}>
                                <input type="text" 
                                  placeholder='Search here' 
                                  className='w-22 form-control'
                                  value={search}
                                  onChange={(e)=> setSearch(e.target.value)}
                                />
                                </div>
                               }
                               />
                          
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GalleryLeads;
