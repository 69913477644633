import React from 'react';
import { useEffect, useState } from "react";
import { sdmUrl } from '../Api';
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom';
import { UserPermissionList } from '../apidata/fetchapi';
import { useIdleTimer } from 'react-idle-timer';
import Admin from '../Admin';

function Sidebar() {

  const { permissionList } = UserPermissionList();
 
  const [isLoggedin, isAuthenticatedUser] = React.useState(false);

  const navigate  = useNavigate();
  const username  = localStorage.getItem('name');
  const userRole  = localStorage.getItem('usrRole');

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('name');
    localStorage.removeItem('isAuthenticatedUser');
    localStorage.removeItem('usrId');
    localStorage.removeItem('usrRole');
    isAuthenticatedUser(false);
    navigate('/');
    
  };

  const allleads = () => {
    sessionStorage.removeItem('leadStatus');
    sessionStorage.setItem('leadStatus', 0);
    navigate("/all_leads");
  }

  const onIdle = () => {
    
    localStorage.removeItem('authToken');
    localStorage.removeItem('name');
    localStorage.removeItem('isAuthenticatedUser');
    localStorage.removeItem('usrId');
    localStorage.removeItem('usrRole');
    isAuthenticatedUser(false);
    navigate('/');
    //insert any custom logout logic here
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000, //10 minute idle timeout
  })

  const usr_token = localStorage.getItem('authToken');

 // console.log(permissionList);
  
  let rolemenuconfig = 0;
  let usermenuconfig = 0;
  let userroleconfig = 0;
  let usermaster = 0;
  let rolemaster = 0;
  let clientmaster = 0;
  { permissionList.map(permissionList =>
    {
      if(permissionList.menuid == 19 && permissionList.web == 1){
        rolemenuconfig = 1;
      }
      if(permissionList.menuid == 53 && permissionList.web == 1){
        usermenuconfig = 1;
      }
      if(permissionList.menuid == 21 && permissionList.web == 1){
        userroleconfig = 1;
      }
      if(permissionList.menuid == 59 && permissionList.web == 1){
        usermaster = 1;
      }
      if(permissionList.menuid == 13 && permissionList.web == 1){
        rolemaster = 1;
      }
      if(permissionList.menuid == 61 && permissionList.web == 1){
        clientmaster = 1;
      }
     
    }
  )}

  const rPServices = 7;
  const nRPServices = 8;
  const logisticsServices = 9;
  const sku = 17;
  const roleMenuMapping = 19;
  const userRoleMapping = 21;
  const leadsBDTeam = 23;
  const opportunityDesignHead = 25;
  const opportunityDesignTeam = 27;
  const leadManagement = 31;

  return (
    <>
      <div className="menu">
        <div className="menu-header">          
          <NavLink to={"/"} className="menu-header-logo">
            {/* <img src="ulms_assets/images/ulms.png" alt="logo" /> */}
            <div className="fw-bold" style={{fontSize: '35px', marginLeft: '60px'}}>Gallery</div>
          </NavLink>
          <a href="index.html" className="btn btn-sm menu-close-btn">
            <i className="bi bi-x"></i>
          </a>
        </div>
        <div className="menu-body">
          <div className="dropdown">
            <a href="#" className="d-flex align-items-center" data-bs-toggle="dropdown">
              <div className="avatar me-3">
                <img src="../../ulms_assets/images/dp.jpg"
                  className="rounded-circle" alt="image" />
              </div>
              <div>
                <div className="fw-bold">{username}</div>
                <small className="text-muted">{userRole}</small>
              </div>
            </a>
            {/* <div className="dropdown-menu dropdown-menu-end">
              <a href="#" className="dropdown-item d-flex align-items-center">
                <i className="bi bi-person dropdown-item-icon"></i> Profile
              </a>
              <a href="#" className="dropdown-item d-flex align-items-center">
                <i className="bi bi-envelope dropdown-item-icon"></i> Inbox
              </a>
              <a href="#" className="dropdown-item d-flex align-items-center" data-sidebar-target="#settings">
                <i className="bi bi-gear dropdown-item-icon"></i> Settings
              </a>
              <a href="login.html" className="dropdown-item d-flex align-items-center text-danger"
                target="_blank">
                <i className="bi bi-box-arrow-right dropdown-item-icon"></i><NavLink onClickCapture={logout} >Logout</NavLink> 
              </a>
            </div> */}
          </div>
          <ul>
            <li className="menu-divider"></li>
            <li>
              <NavLink 
                to={"/"} >
                <span className="nav-link-icon">
                  <i className="bi bi-bar-chart"></i>
                </span>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <li>
              <a href="#">
                <span className="nav-link-icon">
                  <i className="bi bi-truck"></i>
                </span>
                <span>Products</span>
              </a>
              <ul>
                <li>
                  <a href="product-list.html">List
                    View</a>
                </li>
                <li>
                  <a href="product-grid.html">Grid
                    View</a>
                </li>
                <li>
                  <a href="product-detail.html">Product Detail</a>
                </li>
                <li>
                  <a href="shopping-cart.html">Shopping
                    Cart</a>
                </li>
                <li>
                  <a href="checkout.html">Checkout</a>
                </li>
              </ul>
            </li> */}
            <li>          
                <a href="" onClickCapture={logout}>
                    <span className="nav-link-icon">
                        <i className="bi bi-box-arrow-right"></i>
                    </span>
                    <span>Log Out</span>
                </a>  
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar