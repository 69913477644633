import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { sdmUrl } from "../../Api";
// import { DatePicker } from 'antd';
import { Routes, Route, NavLink, useNavigate } from "react-router-dom";
import {
    LeadCount,
    LOBList,
    ProductList,
    ServicesList,
    BDList, 
    SDList, 
} from "../../apidata/fetchapi";
import moment from "moment/moment";
// const {RangePicker} = DatePicker;
import Loader from '../../loader/Loader';

const Dashboard = () => {
    const navigate = useNavigate();

    const usr_token = localStorage.getItem("authToken");

    const [isDashActive, setIsDashActive] = useState(1);
 
    const [loading, setLoading] = useState(false);
    const [lobData, setLobData] = useState([""]);

    let permissionmessage = sessionStorage.getItem("permissionmessage");

    const getLobData = async (e) => {
       
        console.log(JSON.stringify({usr_token}));
        setLoading(true);
        try {
            fetch(sdmUrl.concat("/gallery/lob"), {
                method: "POST",
                body: JSON.stringify({usr_token}),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    console.log(result);
                    setLobData(result);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getLobData();
    }, []);

    const handlelobdata = (data) => {
        console.log(data);
        sessionStorage.removeItem("lobdata");
        sessionStorage.setItem("lobdata", data);
        navigate("/services");
    };

    useEffect(() => {
        if (permissionmessage != "") {
            setTimeout(() => {
                sessionStorage.removeItem("permissionmessage");
            }, 8000);
        }
    }, []);

    return (
        <>
            {permissionmessage && (
                <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                >
                    <strong>{permissionmessage}</strong>
                </div>
            )}
            {loading && (
                <>
                    <Loader/>
                </>
            ) }
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col-md-12" style={{ marginTop: "-8px" }}>
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="d-md-flex gap-4 align-items-center">
                                <div className="d-none d-md-flex"><h3>LOB</h3></div>
                                <div className="d-md-flex gap-4 align-items-center">
                                
                                </div>
                                <div class="dropdown ms-auto">
                                    {/* <a href="#" data-bs-toggle="dropdown"
                                    class="btn btn-primary dropdown-toggle"
                                    aria-haspopup="true" aria-expanded="false">Actions</a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a href="#" class="dropdown-item">Action</a>
                                        <a href="#" class="dropdown-item">Another action</a>
                                        <a href="#" class="dropdown-item">Something else here</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card widget">
                        <div className="row">
                            <div className="row g-4">
                            {lobData.map(data => (
                                <div className="col-md-3" id="box" >
                                    <NavLink to={{pathname:"/services", userProps: {name: "This is a passed prop"}}} onClick={() => handlelobdata(data.value)} >
                                        <div
                                            className= "card bg-teal text-white-90"
                                            style={{ borderRadius: "0px" }} id="button1"
                                        >
                                            <div className="card-body d-flex align-items-center" >
                                                <i className="fa fa-user me-3"></i>
                                                <div className="button1">
                                                    <span style={{ fontSize: "21px" }}>
                                                        {" "}
                                                        {data.value}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
