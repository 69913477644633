import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    
    const navigate = useNavigate();
    const addLead = async () => {     
        navigate("/create_leads");
    }

    return (
        <>
            <div className="header">
                <div className="menu-toggle-btn">
                    <a href="#">
                        <i className="bi bi-list"></i>
                    </a>
                </div>
                <a href="index.html" className="logo">
                    <img width="100" src="https://vetra.laborasyon.com/assets/images/logo.svg" alt="logo" />
                </a>

                {/* <div className="page-title">Overview</div>
                <form className="search-form">
                    <div className="input-group">
                        <button className="btn btn-outline-light" type="button" id="button-addon1">
                            <i className="bi bi-search"></i>
                        </button>
                        <input type="text" className="form-control" placeholder="Search..."
                            aria-label="Example text with button addon" aria-describedby="button-addon1" />
                        <a href="#" className="btn btn-outline-light close-header-search-bar">
                            <i className="bi bi-x"></i>
                        </a>
                    </div>
                </form> */}
                <div className="header-bar ms-auto">
                    <ul className="navbar-nav justify-content-end">
                       <li className="nav-item ms-3" >
                        {/* <Link className="nav-link" onClickCapture={addLead} title="Create Lead" >
                            <img width="100"  id="logo" src='../../ulms_assets/images/edit.gif' alt="logo" />
                        </Link> */}
                            
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link nav-link-notify" data-count="2" >
                                <i className="bi bi-bell icon-lg"></i>
                            </a>
                        </li>
                        
                        <li className="nav-item ms-3">
                            {/* <button className="btn btn-primary btn-icon">
                                <i className="bi bi-plus-circle"></i> Add Product
                            </button> */}
                        </li>
                    </ul>
                </div>

                <div className="header-mobile-buttons">
                    {/* <a href="#" className="search-bar-btn">
                        <i className="bi bi-search"></i>
                    </a> */}
                    <a href="#" className="actions-btn">
                        <i className="bi bi-three-dots"></i>
                    </a>
                </div>

            </div>

        </>
    )
}

export default Header