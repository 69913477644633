import React from 'react';

const Loader = () => {
  return (
    <>
        <div className="preloader">
            <img src="https://vetra.laborasyon.com/assets/images/logo.svg" alt="logo" />
            <div className="preloader-icon"></div>
        </div>
    </>
  )
}

export default Loader