import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";

const UnAuthPages = () => {

  return (
    <React.Fragment>
    <section>
      <Routes>
          <Route path="/" element={<Login />} />   
          <Route path="/reset_password" element={<ForgotPassword />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />   
      </Routes>
    </section>
  </React.Fragment>
  )

}

export default UnAuthPages