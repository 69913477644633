import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../scenes/dashboard";
import GalleryLeads from "../scenes/dashboard/GalleryLeads";
import ImageGallery from "../scenes/dashboard/ImageGallery";
import LeadView from "../scenes/dashboard/LeadView";
import Services from "../scenes/dashboard/Services";


const NavPage = () => {
  return (
    <React.Fragment>
      <section>
        <Routes>
            <Route path="/" element={<Dashboard />} />   
            <Route path="/services" element={<Services />} /> 
            <Route path="/gallery_leads" element={<GalleryLeads />} /> 
            <Route path="/image_gallery/:id" element={<ImageGallery />} /> 
            <Route path="/lead_view/:id" element={<LeadView />} />
        </Routes>
      </section>
    </React.Fragment>
  );
};

export default NavPage;
