import React, { useState, useEffect } from "react";
import { sdmUrl } from "../../Api";
import { useNavigate, useParams, Link } from 'react-router-dom';
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

const ImageGallery = () => {

    let base64     = require('base-64');
    const navigate = useNavigate();

    const [gallery_id, setGalleryId]     = useState(base64.decode(useParams().id));
    const [loading, setLoading]          = useState(false);
    const [solImgData, setSolImgData]    = useState([""]);

    const usr_token = localStorage.getItem("authToken");
    let folder      = sessionStorage.getItem("solutionfolder");

    const handleImagedata = (folder) => {
        console.log(folder);
        sessionStorage.removeItem("solutionfolder");
        sessionStorage.setItem("solutionfolder", folder);

        try {
            fetch(sdmUrl.concat("/gallery/img_list"), {
                method: "POST",
                body: JSON.stringify({usr_token, gallery_id, folder}),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    console.log(result);
                    setSolImgData(result);
    
                });
        } catch (error) {
            console.log(error);
        }

        navigate(`/image_gallery/${base64.encode(gallery_id)}`);
    };

    const getLeadsData = async () => {
       
        console.log(JSON.stringify({usr_token, gallery_id, folder}));
        setLoading(true);
        try {
            fetch(sdmUrl.concat("/gallery/img_list"), {
                method: "POST",
                body: JSON.stringify({usr_token, gallery_id, folder}),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    console.log(result);
                    setSolImgData(result);
    
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getLeadsData();
    }, []);


  return (
    <>

    <div className="row">
        <div className="col-md-12">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                        <div className="d-none d-md-flex"><h3>Solution Gallery</h3></div>
                        <div className="d-md-flex gap-4 align-items-center">
                        </div>
                        <div className="dropdown ms-auto">
                            <Link to={`/gallery_leads`} className="btn btn-primary" > <i className="fa fa-arrow-circle-left m-1"></i> Back</Link>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={`/lead_view/${base64.encode(gallery_id)}`}>
                                 <i className="bi bi-globe2 small me-2"> Lead View</i>
                            </Link>
                        </li>
                        {solImgData != '' && solImgData.path.map((data, index) => (
                            <li className="breadcrumb-item active" aria-current="page"> <Link onClick={() => handleImagedata(data.folderName)}  className="breadcrumb-item active" > {data.folderName} </Link></li>
                        ))}
                    </ol>
                </nav>
            </div>
            <div className="d-none d-md-flex"><h3>Images</h3></div>
            <div className="row g-4">
                {solImgData != '' && solImgData.img.map((data, index) => (
                    <>
                        { (data.type == 'img' || data.type == 'jpg') &&
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card card-hover">
                                <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                    <img src={data.img_url} className="card-img-top" alt="..." style={{height:'350px'}} />
                                </SlideshowLightbox>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <a href="#" className="btn btn-primary">{data.img_name}</a>
                                        {/* <a href="#" className="btn ms-auto">
                                            <i className="bi bi-heart"></i>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>  }
                        { data.type == 'other' &&
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card card-hover">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Link to={data.img_url} className="btn btn-primary">{data.img_name}</Link>
                                        <a href="#" className="btn ms-auto">
                                            {/* <i className="bi bi-heart"></i> */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> }
                    </>
                ))}
            </div>
            <div className="d-none d-md-flex"><h3>Folders</h3></div>
            <div className="row g-4">
                {solImgData != '' && solImgData.folder.map((data, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card card-hover">
                            <div className="card-body">
                                <div className="d-flex">
                                    <Link onClick={() => handleImagedata(data.value)} className="btn btn-primary">{data.value}</Link>
                                    <a href="#" className="btn ms-auto">
                                        {/* <i className="bi bi-heart"></i> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default ImageGallery